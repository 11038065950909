









import { Component, Prop } from "vue-property-decorator";

import WeekScheduleSection from "../components/WeekScheduleSection.vue";

import ReadOneEmployee from "../graphql/queries/ReadOneEmployee.graphql";
import { ReadOneEmployeeQuery } from "../types/types";

import BaseWidget from "./BaseWidget.vue";
import BaseAuth from "../mixins/BaseAuth.vue";

@Component({
  components: {
    WeekScheduleSection,
    BaseWidget,
  },
  apollo: {
    employee: {
      query: ReadOneEmployee,
      fetchPolicy: "cache-first",
      variables() {
        return { id: Number(this.employeeId) };
      },
      update(data: ReadOneEmployeeQuery) {
        return data?.employees[0];
      },
      errorPolicy: "ignore",
    },
  },
})
export default class EmployeeWidget extends BaseAuth {
  @Prop({ type: Number, required: true })
  readonly employeeId: number;

  @Prop({ type: String, required: false })
  readonly successMessage: string;

  @Prop({ type: Boolean, required: false })
  readonly showAppointmentType: boolean;

  @Prop({ type: String, required: false })
  readonly hoverTooltip: string;

  employee: ReadOneEmployeeQuery['employees'][number] | null = null;

  showCreateAppoitment: boolean = false;



  async created() {
    this.$apollo.skipAll = true;
    await this.authorize();
    this.$apollo.skipAll = false;
  }
}
