




import { Vue, Component, Prop } from "vue-property-decorator";
import NoFotoMan from "./NoFotoMan.vue"
import NoFotoWoman from "./NoFotoWoman.vue"
@Component({components: {
    NoFotoMan,
    NoFotoWoman
}})
export default class NoFoto extends Vue {
    @Prop({type: Boolean, required: true})
    readonly gender!: boolean;
}
