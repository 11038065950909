import { IFieldSet } from "../components/FormBuilder/VkForm";

import { getObjectClone } from "../helpers/objectHelper";

import { writeToStorage, clearRecord, readFromStorage } from "./persistedStorageService";

const FORMBACKUP_KEY = "GKBFormDataBackupFront";

interface IFormSavedProps {
  formData: IFieldSet[];
  skip: number;
  take: number;
}

interface IFormRecord {
  formProps: IFormSavedProps;
}

function getRecordKey(formKey: string) {
  return FORMBACKUP_KEY + "_" + formKey;
}

function getFormObject(formProps: IFormSavedProps): IFormRecord {
  return {
    formProps,
  };
}

function getFormPropsFromRecord(formRecord: IFormRecord) {
  return formRecord.formProps;
}

export function saveFormData({ formKey, formProps }: { formKey: string; formProps: IFormSavedProps }) {
  writeToStorage(getRecordKey(formKey), getFormObject(getObjectClone(formProps)));
}

export function retrieveFromData(formKey: string): IFormSavedProps | null {
  const formRecord = readFromStorage<IFormRecord>(getRecordKey(formKey));
  if (!formRecord) return null;

  return getFormPropsFromRecord(formRecord);
}

export function eraseFormData(formKey: string) {
  clearRecord(getRecordKey(formKey));
}
