










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppointmentInfo extends Vue {
  @Prop({ required: true, type: String })
  readonly meta!: string;

  @Prop({ required: true, type: String })
  readonly content!: string;

  @Prop({ required: false, default: ''})
  readonly link!: string;
}
