












import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import VueRecaptcha from "vue-recaptcha";

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class VkRecaptcha extends Vue {
  sitekey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

  @Prop({ type: String, required: false })
  readonly value!: string;

  internalValue = this.value;

  onVerify(response: any) {
    this.internalValue = response;

    this.emitEvents();
  }

  onExpired() {
    this.setCaptchaFalse();
  }

  onError() {
    this.setCaptchaFalse();
  }

  setCaptchaFalse() {
    this.internalValue = "";
    this.emitEvents();
  }

  emitEvents() {
    this.$emit("input", this.internalValue);
    this.$emit("change", this.internalValue);
  }
}
