








































import { Vue, Component, Prop } from "vue-property-decorator";
import NoFoto from "../components/SVG/NoFoto.vue";

@Component({
  components: {
    NoFoto,
  },
})
export default class EmployeePreview extends Vue {
  @Prop({ type: Object, required: true })
  readonly employee!: any;

  IMAGE_SERVER = process.env.VUE_APP_FILES_SERVER_URL || "https://52gkb.ru/vue-files/";
}
