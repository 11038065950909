var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseWidget',[_c('vk-grid',{attrs:{"gutter":"collapse"}},[_c('div',{staticClass:"uk-width-1-12@m uk-dark uk-padding"},[_c('VkForm',{staticClass:"filter-employees-form",attrs:{"actionType":"filter","fetchPolicy":_vm.fetchPolicy,"submitRequest":_vm.filterQuery,"afterSubmit":_vm.updateTable,"skip":_vm.skip,"take":_vm.take,"beforeSubmit":_vm.beforeSubmit,"preserveKey":_vm.preserveKey,"autoupdate":""},on:{"update:skip":function($event){_vm.skip=$event},"update:take":function($event){_vm.take=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var resetForm = ref.resetForm;
return [_c('div',{staticClass:"uk-margin-top"},[_c('vk-button',{on:{"click":resetForm}},[_vm._v("Сбросить фильтры")])],1)]}}])})],1),_c('div',{staticClass:"uk-width-expand@m uk-padding uk-overflow-auto"},[_c('vk-table',{attrs:{"data":_vm.queryResult.filterEmployees.employees,"narrowed":"","hoverable":""}},[_c('vk-table-column',{attrs:{"title":"ФИО","cell":"fullName","unwrapped":"","expanded":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
var row = ref.row;
return _c('div',{},[_c('a',{attrs:{"href":'/employee?employeeId=' + row.id}},[_vm._v(_vm._s(cell))])])}}])}),_c('vk-table-column',{attrs:{"title":"Должность","cell":"position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return _c('div',{},[_vm._v(_vm._s(cell.name))])}}])}),_c('vk-table-column',{attrs:{"title":"Отделение","cell":"departments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return _c('div',{},[_vm._v(_vm._s(cell.map(function (c) { return c.name; }).join(", ")))])}}])})],1),_c('div',{staticClass:"pagination-wrapper"},[_c('vk-pagination',{attrs:{"page":_vm.page,"perPage":_vm.take,"total":_vm.employeeCount},on:{"update:page":function($event){_vm.page=$event}}},[_c('vk-pagination-page-prev'),_c('vk-pagination-pages'),_c('vk-pagination-page-next')],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }