

























import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import BaseWidget from "./BaseWidget.vue";

import CommentsTable from "../components/Comments/CommentsTable.vue";
import CreateCommentModalForm from "../components/Comments/CreateCommentModalForm.vue";
import FilterComments from "../graphql/queries/FilterComments.graphql";
import BaseAuth from "../mixins/BaseAuth.vue";

import { FilterCommentsQuery, FilterCommentsQueryVariables } from "../types/types";

@Component({
  components: {
    BaseWidget,
    CreateCommentModalForm,
    CommentsTable,
  },
  apollo: {
    filterResult: {
      fetchPolicy: "cache-first",
      query: FilterComments,
      update(data: FilterCommentsQuery) {
        return data;
      },

      variables(): FilterCommentsQueryVariables {
        return {
          take: this.take,
          skip: this.skip,
          publicationStatus: "1",
          ...(this.departmentId > 0 ? { departmentId: this.departmentId } : {}),
        };
      },
    },
  },
})
export default class DepartmentCommentsWidget extends BaseAuth {
  page = 1;
  filterResult: FilterCommentsQuery = {
    filterComments: { comments: [], count: 0 },
  };
  @Prop({ type: Number, required: true })
  readonly departmentId: number;

  showCreateComment: boolean = false;
  take = 5;

  closeCreateCommentDialog() {
    return (this.showCreateComment = false);
  }

  openCreateCommentDialog() {
    this.showCreateComment = true;
  }

  get skip() {
    return (this.page - 1) * this.take;
  }

  get commentsCount() {
    return this.filterResult.filterComments.count;
  }

  async created() {
    this.$apollo.skipAll = true;
    await this.authorize();
    this.$apollo.skipAll = false;
  }
}
