








import { Vue, Component, Prop } from "vue-property-decorator";
import { getValidDateString, isValidString } from "./utils";

@Component
export default class Certificate extends Vue {
  @Prop({ type: String, required: false, default: "-" })
  readonly institution!: string;

  @Prop({ type: String, required: false, default: "-" })
  readonly certificateSpecialty!: string;

  @Prop({ type: String, required: false, default: "NaN" })
  readonly certificateDate!: string;

  @Prop({ type: String, required: false, default: undefined })
  readonly series!: string | undefined;

  @Prop({ type: String, required: false, default: undefined })
  readonly number!: string | undefined;

  get composedCertificate() {
    return [
      this.institution,
      this.certificateSpecialty,
      
      getValidDateString(this.certificateDate, (date) => (date as any).toLocaleString("ru-RU", { dateStyle: "short" })),
      this.series,
      this.number,
    ]
      .filter(isValidString)
      .join(". ");
  }

  get isValidCertificate() {
    return this.composedCertificate !== "";
  }
}
