












import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import CommentsTable from "./CommentsTable.vue";

import FilterComments from "../../graphql/queries/FilterComments.graphql";
import { FilterCommentsQuery, FilterCommentsQueryVariables } from "@/types/types";
import BaseAuth from "../../mixins/BaseAuth.vue";

@Component({
  components: { CommentsTable },
  apollo: {
    commentsQueryResult: {
      fetchPolicy: "cache-first",
      query: FilterComments,
      update(data: FilterCommentsQuery) {
        return data;
      },

      variables(): FilterCommentsQueryVariables {
        return {
          take: this.take,
          skip: this.skip,
          publicationStatus: "1",
          ...(this.departmentId > 0 ? { departmentId: this.departmentId } : {}),
          ...(this.employeeId > 0 ? { employeeId: this.employeeId } : {}),
        };
      },
    },
  },
})
export default class CommentsSection extends BaseAuth {
  @Prop({ type: Number, required: false, default: -1 })
  readonly employeeId!: number;

  @Prop({ type: Number, required: false, default: -1 })
  readonly departmentId!: number;

  @Prop({ type: Function, default: () => {}, required: false })
  readonly openCreateCommentDialog!: Function;

  commentsQueryResult: FilterCommentsQuery = {
    filterComments: { comments: [], count: 0 },
  };

  page = 1;
  take = 5;

  get skip() {
    return (this.page - 1) * this.take;
  }

  get commentsCount() {
    return this.commentsQueryResult.filterComments.count;
  }

  async created() {
    this.$apollo.skipAll = true;
    await this.authorize();
    this.$apollo.skipAll = false;
  }
}
