
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IFieldSet } from '../FormBuilder/VkForm';
import VkFieldSet from '../../components/FormBuilder/Elements/VkFieldSet.vue';
import VkImputBuilder from '../../components/FormBuilder/Elements/VkImputBuilder.vue';

@Component({
	components: {
		'vk-fieldset': VkFieldSet,
		'vk-input-builer': VkImputBuilder,
	},
})
export default class DefautFormBuilder extends Vue {
	@Prop({ required: true, type: Array }) formData!: IFieldSet[];
	@Prop({ required: false, type: String, default: '1-1' }) 
	readonly childWidth!: String
}
