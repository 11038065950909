














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ name: 'vk-fieldset' })
export default class VkFieldSet extends Vue {
	@Prop({ required: true, type: String })
	readonly title!: string;

	@Prop({ required: false, default: false, type: Boolean })
	readonly wide!: boolean;

	@Prop({ required: false, default: false, type: Boolean })
	readonly hideName!: boolean;

	@Prop({ required: false, default: false, type: Boolean })
	readonly oneRow!: boolean;


}
