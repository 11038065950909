import { FilterCommentsQueryVariables } from "./../types/types";
import FilterEmployees from "../graphql/queries/FilterEmployees.graphql";

import FilterComments from "../graphql/queries/FilterComments.graphql";
import AutocompleteDepartments from "../graphql/queries/AutocompleteDepartments.graphql";

import { FilterEmployeesQueryVariables } from "../types/types";

import { IFieldSet } from "../components/FormBuilder/VkForm";
import { DocumentNode } from "graphql";

module GQLFilterConverter {
  export function convertInput(fieldSets: IFieldSet[], request: DocumentNode) {
    return requestMap.find(r => r.request === request)!.convertInput(fieldSets)!;
  }

  export function initInput(request: DocumentNode): IFieldSet[] {
    return requestMap.find(r => r.request === request)!.initInput()!;
  }

  const requestMap = [
    {
      request: FilterEmployees,
      initInput: (): IFieldSet[] => [
        {
          name: "",
          wide: true,
          fields: [
            {
              name: "ФИО",
              value: "",
              rules: "",
            },
            {
              name: "Должность",
              rules: "",

              value: "",
            },
            {
              name: "Отделение",
              type: "autocomplete",
              rules: "",
              value: 0,
              optionsQuery: AutocompleteDepartments,
            },
          ],
        },
      ],
      convertInput: (fieldSets: IFieldSet[]): FilterEmployeesQueryVariables => {
        const fields = fieldSets[0].fields;
        let result = {};

        if (fields[0].value !== "") {
          result = {
            ...result,
            fullNameSearch: fields[0].value.toString(),
          };
        }

        if (fields[1].value !== "") {
          result = {
            ...result,
            positionSearch: fields[1].value.toString(),
          };
        }

        if (fields[2].value !== 0) {
          result = {
            ...result,
            departmentId: fields[2].value as number,
          };
        }

        return result;
      },
    },

    {
      request: FilterComments,
      initInput: (): IFieldSet[] => [
        {
          name: "Фильтры",
          wide: true,
          fields: [
            {
              name: "фамилию врача",
              value: "",
              rules: "",
            },
          ],
        },
      ],
      convertInput: (fieldSets: IFieldSet[]): FilterCommentsQueryVariables => {
        const fields = fieldSets[0].fields;
        let result = {
          publicationStatus: "1", //только опубликованные
        };

        if (fields[0].value !== "") {
          result = {
            ...result,
            fullNameSearch: fields[0].value.toString(),
          } as any;
        }

        return result;
      },
    },
  ];
}

export default GQLFilterConverter;
