










import { Vue, Component, Prop } from "vue-property-decorator";


@Component
export default class CommentsTableRowBody extends Vue {
  @Prop({ type: Object, required: true })
  readonly comment!: Object;
  
}
