


















import { ScheduleInterval } from '@/helpers/datesHelpers';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ScheduleIntervals extends Vue {

  @Prop({ type: Array, required: true })
  readonly intervals!: ScheduleInterval[];

  @Prop({ type: Function, required: true })
  readonly onIntervalClick!: (scheduleId: number) => void;

  @Prop({ type: String, required: false, default: 'Записаться на прием' })
  readonly hoverTooltip: string;
}
