type CheckFunction<T> = (obj: T) => boolean;
type CompareFunction<T> = (a: T, b: T) => number;

type CompareValidator<T> = {
  type: "compare";
  compare: CompareFunction<T>;
};

type CheckValidator<T> = {
  type: "check";
  check: CheckFunction<T>;
};

type Validator<T> = CheckValidator<T> | CompareValidator<T>;

function isCompare<T>(validator: Validator<T>): validator is CompareValidator<T> {
  return validator.type === "compare";
}

export function makeCompare<T>(compare: CompareFunction<T>): CompareValidator<T> {
  return {
    type: "compare",
    compare,
  };
}

export function makeCheck<T>(check: CheckFunction<T>): CheckValidator<T> {
  return {
    type: "check",
    check,
  };
}

type ValidatorGroup<T> = Validator<T>[];

export function combineByPriority<T>(validators: (Validator<T> | ValidatorGroup<T>)[]): CompareFunction<T> {
  return (a, b) => {
    for (const validator of validators) {
      if (Array.isArray(validator)) {
        // Добавить приоритет????!??!?

        const firstValidator = validator[0];

        if (!isCompare(firstValidator)) {
          const isATrue = firstValidator.check(a);
          const isBTrue = firstValidator.check(b);

          if (isATrue || isBTrue) {
            return combineByPriority(validator)(a, b);
          } else {
            continue;
          }
        } else {
          return combineByPriority(validator)(a, b);
        }

      }

      if (isCompare(validator)) {
        const compareResult = validator.compare(a, b);
        if (compareResult !== 0) return compareResult;
      } else {
        const isATrue = validator.check(a);
        const isBTrue = validator.check(b);

        if ((isATrue || isBTrue) && !(isATrue && isBTrue)) {
          if (isATrue) return -1;
          return 1;
        }
      }
    }
    return 0;
  };
}