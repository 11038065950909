














import { Vue, Component, Prop } from "vue-property-decorator";

import {IComment} from "./helpers";

@Component
export default class CommentsTableRowHeader extends Vue {
  @Prop({ type: Object, required: true })
  readonly comment!: IComment;
  
  @Prop({type: Boolean, required: false, default: false}) 
  readonly shortened!: boolean;

  get localeDate() {
    //@ts-ignore
    return (new Date(this.comment.commentDate)).toLocaleString('ru-RU', {dateStyle: 'short' })
  }

  get shortName() {
      if (!this.comment.employee) return '';

      return `${this.comment.employee.lastName} ${this.comment.employee.firstName.charAt(0)}. ${this.comment.employee.middleName.charAt(0)}.`
  }
  
  get employeeLink() {
    if (!this.comment.employee) return '';

    return '/employee?employeeId='+this.comment.employee.id;
  }
}
