import { InitEmployeeCommentQuery } from "./../types/types";

import AutocompleteDepartments from "../graphql/queries/AutocompleteDepartments.graphql";
import AutocompleteEmployees from "../graphql/queries/AutocompleteEmployees.graphql";
import InitEmployeeComment from "../graphql/queries/InitEmployeeComment.graphql";

import { IFieldSet, SelectOptions } from "../components/FormBuilder/VkForm";
import { DocumentNode } from "graphql";

module GQLFormDataConverter {
  export function convertOutput(result: any, request: DocumentNode): IFieldSet[] {
    return requestMap.find(r => r.request === request)!.convertOutput(result)!;
  }

  interface ConverOutputMap {
    request: DocumentNode;
    convertOutput: (result: any | null | undefined) => IFieldSet[];
  }

  const requestMap: ConverOutputMap[] = [
    {
      request: InitEmployeeComment,
      convertOutput: (result: InitEmployeeCommentQuery | null | undefined): IFieldSet[] => {
        const employee = result?.employee;

        return [
          {
            name: "Кому", //0
            hideName: true,

            fields: [
              {
                name: "Отделение",
                hideName: true,

                type: "autocomplete",
                rules: "",
                value: employee?.departments?.[0].id ?? 0, //0
                optionsQuery: AutocompleteDepartments,
                readonly: employee?.departments?.[0].id > 0,
              },
              {
                name: "Ф.И.О. врача",
                rules: "",
                type: "autocomplete",
                hideName: true,
                customPlaceholder: "Укажите Ф.И.О. врача...",

                optionsQuery: AutocompleteEmployees,
                value: employee?.id ?? 0, //1
                readonly: employee?.id > 0,

                ...(employee?.departments?.[0].id > 0
                  ? {
                      customArgs: {
                        departmentId: employee?.departments?.[0].id,
                      },
                    }
                  : {}),
              },
            ],
          },
          {
            name: "От кого", //1
            hideName: true,

            fields: [
              {
                name: "Ваше имя",
                value: "", //0
                hideName: true,
                customPlaceholder: "Укажите Ваше имя...",
              },
              {
                name: "Ваш email",
                rules: "email|required",
                value: "", //1
                hideName: true,
                customPlaceholder: "Укажите Ваш email...",
              },
            ],
          },
          {
            name: "Текст отзыва", //2
            hideName: true,
            fields: [
              {
                name: "Ваш отзыв",
                hideName: true,

                value: "", //0
                type: "textarea",
                rules: "required|no-swear",
                customPlaceholder: "Ваш отзыв...",
              },
            ],
          },
          {
            name: "Валидация пользователя", //3
            hideName: true,
            fields: [
              {
                name: "Капча",
                value: "", //0
                type: "recaptcha",
                rules: "required",
              },
              {
                name:
                  'С <a href="/files/Политика конфиденциальности.pdf" title="Политика конфиденциальности" target="_blank">Политикой конфиденциальности</a> ознакомлен',
                value: false, //1
                type: "checkbox",
                rules: "is-true|required",
              },
              {
                name:
                  'Я выражаю согласие на обработку персональных данных. <a href="/klinika/personaldata" target="_blank" title="Информация о персональных данных">Подробнее</a>',
                value: false, //2
                type: "checkbox",
                rules: "is-true|required",
              },
            ],
          },
        ];
      },
    },
  ];
}

export default GQLFormDataConverter;
