var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('autocomplete',{ref:"autocomplete",attrs:{"disabled":_vm.disabled,"search":_vm.search,"placeholder":_vm.placeholder,"aria-label":_vm.placeholder,"get-result-value":_vm.getResultValue},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rootProps = ref.rootProps;
var inputProps = ref.inputProps;
var inputListeners = ref.inputListeners;
var resultListProps = ref.resultListProps;
var resultListListeners = ref.resultListListeners;
var results = ref.results;
var resultProps = ref.resultProps;
return [_c('div',_vm._b({},'div',rootProps,false),[_c('span',{staticClass:"uk-form-icon uk-form-icon-flip"},[_c('vk-icons-chevron-down')],1),_c('input',_vm._g(_vm._b({staticClass:"uk-input",on:{"focus":function($event){return $event.target.select()}}},'input',inputProps,false),Object.assign({}, inputListeners, {blur: _vm.onInputValueChange}))),_c('div',_vm._g(_vm._b({staticClass:"uk-card uk-background-default uk-box-shadow-medium uk-overflow-auto",staticStyle:{"height":"200px"}},'div',resultListProps,false),resultListListeners),[_c('table',{staticClass:"uk-table uk-table-hover uk-table-divider"},[_c('tbody',_vm._l((results),function(result,index){return _c('tr',_vm._b({key:resultProps[index].id,staticStyle:{"cursor":"pointer"}},'tr',resultProps[index],false),[_c('td',[_vm._v(_vm._s(_vm.getResultValue(result)))])])}),0)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }