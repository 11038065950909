











import { Vue, Component, Prop } from "vue-property-decorator";
import BaseWidget from "./BaseWidget.vue";

import ReadDepartmentEmployees from "../graphql/queries/ReadDepartmentEmployees.graphql";
import { ReadDepartmentEmployeesQuery } from "../types/types";
import BaseAuth from "../mixins/BaseAuth.vue";

import EmployeePreview from "../components/EmployeePreview.vue";

import { combineByPriority, makeCompare, makeCheck } from '../utils/sorting';

type Employee = ReadDepartmentEmployeesQuery["employees"][0];

function isManagingDepartment(employee: Employee) {
  return employee.managingDepartments.length > 0;
}

function isNurse(employee: Employee): boolean {
  const name = employee.position.name;

  const medbro = "медицинский брат",
    medsis = "медицинская сестра";

  return name.toLowerCase().includes(medbro) || name.toLowerCase().includes(medsis);
}

function isBigNurse(employee: Employee) {
  const name = employee.position.name;

  return name.toLowerCase().includes("старший") || name.toLowerCase().includes("старшая");
}

function isHaveCategory(employee: Employee) {
  return employee.qualifications.length > 0;
}

function isDoctor(employee: Employee) {
  return employee.position.name.toLowerCase().includes('врач');
}

function isNotTrainee(employee: Employee) {
  return !employee.position.name.toLowerCase().includes('стажер');
}

function sortByCategory(a: Employee, b: Employee): number {
  const qualificationCategoryNames = ["высшая", "вторая", "первая"];
  const aName = a.qualifications[0]?.qualificationCategory.name;
  const bName = b.qualifications[0]?.qualificationCategory.name;

  if (!aName && !bName) return 0;

  return qualificationCategoryNames.indexOf(aName) - qualificationCategoryNames.indexOf(bName);
}

function alphaSort(a: Employee, b: Employee) {
  if (!a.fullName || !b.fullName) return 0;

  if (a.fullName < b.fullName) {
    return -1;
  }
  if (a.fullName > b.fullName) {
    return 1;
  }
  return 0;
}

function byLogic(a: Employee, b: Employee) {
  return combineByPriority([
    makeCheck(isManagingDepartment),
    [
      makeCheck(isDoctor),
      makeCheck(isHaveCategory),
      makeCompare(sortByCategory),
      makeCheck(isNotTrainee),
      makeCompare(alphaSort),
    ],

    [
      makeCheck(isNurse),
      makeCheck(isBigNurse),
      makeCheck(isHaveCategory),
      makeCompare(sortByCategory),
      makeCompare(alphaSort),
    ],

    makeCompare(alphaSort),
  ])(a, b);
}

function byId(id: number) { 
  return (employee: Employee) => employee.id === id 
};

function byIds(ids: number[]) {
  return ids.map((id) => makeCheck(byId(id)));
}

function hardcodeAdmins(a: Employee, b: Employee) {
  return combineByPriority([...byIds([
    2580,
    2944,
    2693,
    2820,
    2587,
    2533,
    2891,
    1121,
    3095,
    3093,
    3094,
  ]),
  makeCompare(alphaSort)
])(a, b);
}

const ADMINS_DEPARTMENT_ID = 82;

@Component({
  components: { BaseWidget, EmployeePreview },
  apollo: {
    employees: {
      query: ReadDepartmentEmployees,
      variables() {
        return { departmentId: Number(this.departmentId) };
      },
      update(data: ReadDepartmentEmployeesQuery) {
        if (Number(this.departmentId) === ADMINS_DEPARTMENT_ID) {
          return data?.employees.sort(hardcodeAdmins);
        }

        return data?.employees.sort(byLogic);
      },
      errorPolicy: "ignore",
    },
  },
})
export default class DepartmentEmployeesWidget extends BaseAuth {
  employees: any = null;
  @Prop({ type: Number, required: true })
  readonly departmentId!: number;

  async created() {
    this.$apollo.skipAll = true;
    await this.authorize();
    this.$apollo.skipAll = false;
  }
}
