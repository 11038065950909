


























import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { WeekScheduleRow, WeekScheduleHeader, getDateLabel, compareByDate, toHM } from "../helpers/datesHelpers";
import { isThisWeek, startOfDay, addDays, isBefore } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

import ScheduleIntervals from "./ScheduleIntervals.vue";
import CreateAppointmentForm from "./CreateAppointmentForm.vue";
import { Employee, Schedule, ReadEmployeeScheduleQueryVariables, ReadEmployeeScheduleQuery } from "@/types/types";

import ReadEmployeeSchedule from "../graphql/queries/ReadEmployeeSchedule.graphql";

@Component({
  components: {
    ScheduleIntervals,
    CreateAppointmentForm,
  },
  apollo: {
    schedules: {
      query: ReadEmployeeSchedule,
      fetchPolicy: "cache-first",
      variables(): ReadEmployeeScheduleQueryVariables {
        return {
          employeeId: Number(this.employee.id),
          from: this.dateFrom,
          to: this.dateTo,
        };
      },
      update(data: ReadEmployeeScheduleQuery) {
        return data.schedules;
      },
      errorPolicy: "ignore",
    },
  },
})
export default class WeekScheduleSection extends Vue {
  schedules: Schedule[] = [];

  @PropSync("showCreateAppoitment", { type: Boolean, required: true })
  showCreateAppoitmentProp: boolean;

  @Prop({ type: Object, required: true })
  readonly employee!: Employee;

  @Prop({ type: Boolean, required: false, default: true })
  readonly showHeader!: boolean;

  @Prop({ type: String, required: false })
  readonly successMessage: string;

  @Prop({ type: Boolean, required: false })
  readonly showAppointmentType: boolean;

  @Prop({ type: String, required: false })
  readonly hoverTooltip: string;

  get weekHeaders(): WeekScheduleHeader[] {
    const result: WeekScheduleHeader[] = [];

    for (let dayInc = 0; dayInc < 7; dayInc++) {
      const newDay = new Date(this.dateFrom);
      newDay.setDate(newDay.getDate() + dayInc);
      const newHeader: WeekScheduleHeader = {
        date: newDay,
        dateLabel: getDateLabel(newDay),
      };
      result.push(newHeader);
    }

    return result;
  }

  get weekScheduleRows(): WeekScheduleRow[] {
    if (this.schedules.length === 0 || this.weekHeaders.length === 0) return [];

    const newWeekSchedule: WeekScheduleRow[] = [
      {
        schedule: this.weekHeaders.map((wH) => ({
          intervals: [],
          date: new Date(wH.date),
        })),
      },
    ];

    const endOfSevedDays = addDays(startOfDay(new Date()), 7);
    const thisWeekSchedules = this.schedules.filter(({ timeBegin }) => isBefore(new Date(timeBegin), endOfSevedDays));

    for (const schedule of thisWeekSchedules) {
      const scheduleDate = new Date(schedule.scheduleDate);

      const inWeekIndex = newWeekSchedule[0].schedule.findIndex((sch) => compareByDate(sch.date, scheduleDate));
      if (inWeekIndex < 0) continue;

      const timeBegin = new Date(schedule.timeBegin);
      const timeEnd = new Date(schedule.timeEnd);

      const newInterval = `${toHM(timeBegin)} - ${toHM(timeEnd)}`;

      newWeekSchedule[0].schedule[inWeekIndex].intervals.push({
        name: newInterval,
        id: schedule.id,
        isFree: schedule.freeIntervals.length > 0,
      });
    }

    return newWeekSchedule;
  }

  selectedScheduleId = -1;
  selectedScheduleDate: Date = null;

  get dateFrom() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  get dateTo() {
    const today = new Date();
    today.setDate(today.getDate() + 7);
    today.setHours(23, 59, 59, 59);
    return today;
  }

  closeCreateAppoitmentDialog() {
    this.showCreateAppoitmentProp = false;
  }

  openCreateApoitment(scheduleId: number, scheduleDate: Date) {
    this.selectedScheduleId = scheduleId;
    this.selectedScheduleDate = scheduleDate;
    this.showCreateAppoitmentProp = true;
  }
}
