export interface ScheduleInterval {
  name: string;
  id: number;
  isFree: boolean;
}

export interface WeekScheduleRow {
  cabinet?: string;
  schedule: {
    intervals: ScheduleInterval[];
    date: Date;
  }[];
}

export interface WeekScheduleHeader { date: Date, dateLabel: string };

export function isThisWeek(date: Date) {
  const now = new Date();

  const weekDay = (now.getDay() + 6) % 7; // Make sure Sunday is 6, not 0
  const monthDay = now.getDate();
  const mondayThisWeek = monthDay - weekDay;

  const startOfThisWeek = new Date(+now);
  startOfThisWeek.setDate(mondayThisWeek);
  startOfThisWeek.setHours(0, 0, 0, 0);

  const startOfNextWeek = new Date(+startOfThisWeek);
  startOfNextWeek.setDate(mondayThisWeek + 7);

  return date >= startOfThisWeek && date < startOfNextWeek;
}

export function getWeekDates(date: Date) {
  let week: Date[] = [];
  let current = new Date(date);

  // Starting Monday not Sunday
  current.setDate(current.getDate() - current.getDay() + 1);
  for (let i = 0; i < 7; i++) {
    week.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }
  return week;
}


// 0 => 6
// 1 => 0
// 2 => 1
// 3 => 2
// 4 => 3
// 5 => 4
// 6 => 5
export function convertDayOfTheWeek(dayOfTheWeek: number) {
  return dayOfTheWeek === 0 ? 6 : dayOfTheWeek - 1;
}

export function padNumber(n: number) {
  return `00${n}`.slice(-2);
}

export function toHM(date: Date) {
  return `${padNumber(date.getHours())}:${padNumber(
    date.getMinutes()
  )}`;
}

type DayOfTheWekNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export function getDayOfTheWeekString(dayOfTheWeek: DayOfTheWekNumber) {

  
  const dayOfTheWeekMap = {
    0: 'Вс',
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб',
  };

  return dayOfTheWeekMap[dayOfTheWeek];
}

export function getDateLabel(date: Date) {
  return `${getDayOfTheWeekString(date.getDay() as DayOfTheWekNumber)}. ${padNumber(date.getDate())}.${padNumber(date.getMonth() + 1)}`;
}

export function compareByDate(dateA: Date, dateB: Date) {
  return dateA.getFullYear() === dateB.getFullYear() 
      && dateA.getMonth() === dateB.getMonth()
      && dateA.getDay() === dateB.getDay()
}

export function diffByDate(dateA: Date, dateB: Date) {
  const diffYear = dateA.getFullYear() - dateB.getFullYear();
  if (diffYear !== 0) return diffYear;

  const diffMonth = dateA.getMonth() - dateB.getMonth();
  if (diffMonth !== 0) return diffMonth;

  const diffDay = dateA.getDay() - dateB.getDay();
  return diffDay;
}