
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { DocumentNode } from "graphql";

import GQLAutocompleteConverter from "../../../api/GQLAutocompleteConverter";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import BaseAuth from "../../../mixins/BaseAuth.vue";

@Component({
  components: {
    Autocomplete,
  },
})
export default class VkAutocompleteInput extends BaseAuth {
  @Prop({ type: String, default: "Введите значение..." })
  readonly placeholder!: string;

  @Prop({ type: Boolean, default: false, required: false })
  readonly disabled!: boolean;

  @Prop({ required: true, type: Object })
  readonly itemsQuery!: DocumentNode;

  @Prop({ required: true, type: Number })
  readonly value!: number;

  @Prop({ required: false, type: Object })
  readonly customArgs!: Object;

  @Ref()
  readonly autocomplete!: Autocomplete;

  internalValue = -1;
  inputValue = "";
  options: [number, string][] = [];

  get selectedValue() {
    let result = this.internalValue === -1 ? "" : this.options.find(([id, _]) => id === this.internalValue)?.[1] ?? "";

    return result;
  }

  onInputValueChange(e: any) {
    if (e.target.value === "") {
      this.internalValue = 0;
      this.$emit("input", 0);
    }
  }

  get inputThreshold() {
    //49 сотрудников - норм показывать
    if (this.options.length > 50) {
      return 3;
    }

    return 0;
  }

  search(input: string): [number, string][] {
    const lowCaseInput = input.trim().toLowerCase();
    if (lowCaseInput.length < this.inputThreshold) {
      return [];
    }
    return this.options.filter((o) => o[1].toLowerCase().includes(lowCaseInput));
  }

  getResultValue(result: [number, string]) {
    return result[1];
  }

  handleSubmit(result: [number, string]) {
    this.internalValue = result[0];
    this.$emit("input", result[0]);
  }

  handleInput(e: any) {
    this.inputValue = e.target.value;
  }

  @Watch("value")
  onValueChange(n: number, o: number) {

    if (o !== n && n === 0) {
      this.internalValue = 0;
      this.autocomplete.value = "";
    }
  }

  //fetch из query
  async mounted() {
    await this.authorize();

    const { data } = await this.$apollo.query({
      query: this.itemsQuery,
      ...(this.customArgs ? { variables: this.customArgs } : {}),
    });

    this.options = GQLAutocompleteConverter.convertOutput(data, this.itemsQuery);
    this.internalValue = this.value;
    if (this.autocomplete) {
      this.autocomplete.value = this.selectedValue;
    }
  }
}
