
export function writeToStorage<T extends object>(key: string, data: T) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

export function clearRecord(key: string) {
  window.localStorage.removeItem(key);
}

export function readFromStorage<T extends object>(key: string): T | null {
  const objectString = window.localStorage.getItem(key);
  if (objectString === '') return null;

  const resultObject = JSON.parse(objectString);
  return resultObject;
}