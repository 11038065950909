export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const getValidDateString = (graduationYear: string, formater: (date: Date) => string) => {
  const graduationDate = new Date(graduationYear);
  if (!isValidDate(graduationDate) || graduationDate.getFullYear() < 1910) {
    return "-";
  }

  return formater(graduationDate);
};

export const isValidString = (rawString: string) =>
  rawString !== undefined && rawString !== null && rawString !== "" && rawString !== "-";
