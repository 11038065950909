





import { Vue, Component, Prop } from "vue-property-decorator";

import Vuikit from "vuikit";
import VuikitIcons from "@vuikit/icons";

Vue.use(Vuikit);
Vue.use(VuikitIcons);

@Component
export default class BaseWidget extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly container!: boolean;
}
