



















































import { Component, Vue, Provide, Watch, Ref } from "vue-property-decorator";
import VkForm from "../components/FormBuilder/VkForm.vue";
import CommentsSection from "../components/Comments/CommentsSection.vue";
import WeekScheduleSection from "../components/WeekScheduleSection.vue";
import EmployeeDescriptionSection from "../components/EmployeeDescriptionSection/index.vue";

import CreateCommentModalForm from "../components/Comments/CreateCommentModalForm.vue";

import ReadOneEmployee from "../graphql/queries/ReadOneEmployee.graphql";
import { ReadOneEmployeeQuery, Schedule, SignInMutation } from "../types/types";
import NoFoto from "../components/SVG/NoFoto.vue";

import BaseWidget from "./BaseWidget.vue";
import VueHtml2pdf from "vue-html2pdf";
import BaseAuth from "../mixins/BaseAuth.vue";

@Component({
  components: {
    VkForm,
    CommentsSection,
    WeekScheduleSection,
    EmployeeDescriptionSection,
    CreateCommentModalForm,
    BaseWidget,
    VueHtml2pdf,
    NoFoto,
  },
  apollo: {
    employee: {
      query: ReadOneEmployee,
      fetchPolicy: "cache-first",
      variables() {
        return { id: Number(this.employeeId) };
      },
      update(data: ReadOneEmployeeQuery) {
        document.title = data.employees[0]?.fullName ?? "Идет загрузка...";
        return data?.employees[0];
      },
      errorPolicy: "ignore",
    },
  },
})
export default class EmployeeWidget extends BaseAuth {
  IMAGE_SERVER = process.env.VUE_APP_FILES_SERVER_URL || "https://52gkb.ru/vue-files/";

  employee: any = null;

  pdfProgress = 100;

  showCreateComment: boolean = false;
  showCreateAppoitment: boolean = false;

  closeCreateCommentDialog() {
    return (this.showCreateComment = false);
  }

  openCreateCommentDialog() {
    this.showCreateComment = true;
  }

  get employeeId(): number {
    const url = new URL(window.location.href);
    return Number(url.searchParams.get("employeeId"));
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  onPDFProgress(progress: number) {
    this.pdfProgress = progress;
  }

  get pdfFileName() {
    return this.employee?.fullName ?? "NoEmployee";
  }

  afterSubmit() {
    location.reload();
  }

  @Ref("html2Pdf")
  readonly html2Pdf!: VueHtml2pdf;

  downloadPDF() {
    this.scrollToTop();
    this.html2Pdf.generatePdf();
  }

  async created() {
    this.$apollo.skipAll = true;
    await this.authorize();
    this.$apollo.skipAll = false;
  }
}
