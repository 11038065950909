






























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import VkLabel from "./VkLabel.vue";
import { FieldType, SelectOptions } from "../../../components/FormBuilder/VkForm";
import VkSelect from "../../../components/FormBuilder/Elements/VkSelect.vue";
import { DocumentNode } from "graphql";
import VkAutocompleteInput from "../../../components/FormBuilder/Elements/VkAutocompleteInput.vue";
//не трогать, только так работает
//@ts-ignore
import VkRecaptcha from "../../../components/FormBuilder/Elements/VkRecaptcha.vue";

const { ru: datepickerLocale } = require("vuejs-datepicker/dist/locale");

@Component({
  components: {
    ValidationProvider,
    VkLabel,
    VkSelect,
    VkAutocompleteInput,
    VkRecaptcha,
  },
  name: "vk-input-builder",
})
export default class VkImputBuilder extends Vue {
  @Prop({
    type: [String, Number, Object, Boolean, Date, Array, File],
    required: true,
  })
  readonly value!: string | number | object | boolean | File;

  @Prop({ type: Boolean, default: false, required: false })
  readonly hideName!: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  readonly readonly!: boolean;

  @Prop({ type: String, default: "required" })
  readonly rules!: string;

  @Prop({ type: String, required: true })
  readonly name!: string;

  @Prop({ type: String, default: "text" })
  readonly type!: FieldType;

  @Prop({ type: Object, required: false })
  readonly options!: SelectOptions;

  @Prop({ type: Object, required: false })
  readonly optionsQuery!: DocumentNode;

  @Prop({ type: Object, required: false })
  readonly customArgs!: Object;

  @Prop({ type: String, required: false })
  readonly customPlaceholder!: string | undefined;

  fieldValue = this.value;

  //костыль не трогать!
  data() {
    return {
      datepickerLocale,
    };
  }

  fieldPlaceholder(fieldName: string) {
    return this.customPlaceholder ?? `Укажите ${fieldName.toLowerCase()}...`;
  }
}
