















import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import { SelectOptions } from '../../../components/FormBuilder/VkForm';

@Component
export default class VkSelect extends Vue {
    @Prop({type: String, default: ''})
    readonly value!: string;

    @Prop({type: String, default: 'Выберете...'})
    readonly placeholder!:string;

	@Prop({type: Boolean, required: false, default: true})
	readonly needPlaceholder!: boolean;

    @Prop({ type: Object, required: true })
    readonly options!: SelectOptions;
	
    input(e: any) {
        this.$emit('input', e.target.value);
    }
}
