












































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

import ReadOneSchedule from "../graphql/queries/ReadOneSchedule.graphql";
import VkForm from "../components/FormBuilder/VkForm.vue";
import CreateOneApoitment from "../graphql/mutations/CreateOneApoitment.graphql";
import { SelectOption, SelectOptions } from "./FormBuilder/VkForm";
import VkInputBuilder from "../components/FormBuilder/Elements/VkImputBuilder.vue";
import {
  CreateOneApoitmentMutation,
  CreateOneApoitmentMutationVariables,
  ReadOneScheduleQuery,
  ReadOneScheduleQueryVariables,
} from "@/types/types";
import { toHM } from "@/helpers/datesHelpers";
import { PropType } from "@/utils/utility-types";

type ScheduleInfo = PropType<ReadOneScheduleQuery, "schedule">;

const compareByHM = (a: Date, b: Date) => {
  const aDate = new Date(a),
    bDate = new Date(b);
  const hResult = aDate.getHours() - bDate.getHours();
  if (hResult === 0) return aDate.getMinutes() - bDate.getMinutes();
  return hResult;
};

const shouldInclude = ({ value: { timeEnd } }: SelectOption, { value: { timeBegin } }: SelectOption): boolean => {
  return compareByHM(timeEnd, timeBegin) === 0;
};

@Component({
  components: {
    VkForm,
    VkInputBuilder,
    ValidationObserver,
  },
  apollo: {
    schedule: {
      query: ReadOneSchedule,

      variables(): ReadOneScheduleQueryVariables {
        return {
          id: this.scheduleId,
        };
      },
      update(data: ReadOneScheduleQuery): ScheduleInfo {
        return data.schedule;
      },

      skip() {
        return !this.show;
      },
    },
  },
})
export default class CreateAppointmentForm extends Vue {
  @Prop({ type: String, required: true })
  readonly lastNameAndInitials!: string;

  @Prop({ type: String, required: true })
  readonly position!: string;

  @Prop({ type: Date, required: true })
  readonly date!: Date;

  @Prop({ type: Number, required: true })
  readonly scheduleId!: number;

  @Prop({ type: String, required: false, default: "" })
  readonly imageURL!: string;

  @Prop({ type: Boolean, required: true })
  readonly show!: boolean;

  @Prop({ type: Function, required: true })
  readonly onClose!: Function;

  @Prop({ type: String, required: false, default: 'Мы записали Вас на прием!' })
  readonly successMessage: string;

  @Prop({ type: Boolean, required: false, default: true })
  readonly showAppointmentType: boolean;

  get localeDate() {
    return this.date.toLocaleString("ru-RU", { dateStyle: "short" } as any);
  }

  IMAGE_SERVER = process.env.VUE_APP_FILES_SERVER_URL || "https://52gkb.ru/vue-files/";

  appoitmentQuery = CreateOneApoitment;

  showSuccessMessage = false;
  showErrorMessage = false;
  errorMessage = '';

  disabled = false;
  newAppointmentId = -1;

  appointmentTypeSelectOptions: SelectOptions = {
    first: {
      value: "first",
      label: "Первичный прием",
    },
    second: {
      value: "second",
      label: "Повторный прием",
    },
  };

  schedule: ScheduleInfo = null;

  get timeOptionsBuffer(): SelectOptions {
    if (!this.schedule || !this.schedule.freeIntervals) return {};

    const schedule = this.schedule;
    const intevalsOptions: SelectOptions = {};

    for (let i = 0; i < schedule.freeIntervals!.length; i++) {
      const { timeBegin } = schedule.freeIntervals![i]!;

      intevalsOptions[i.toString()] = {
        label: `${toHM(new Date(timeBegin))}`,
        value: schedule.freeIntervals![i],
      };
    }
    return intevalsOptions;
  }

  get cleanPosition() {
    return this.position === '-' ? '' : this.position;
  }

  get cabinetNumber() {
    return this.schedule?.cabinet ? this.schedule.cabinet.number : "";
  }

  get scheduleTagsLabel() {
    if (!this.schedule || this.schedule.scheduleTags.length === 0) return "";
    return this.schedule.scheduleTags.map((sT) => sT.name).join(", ");
  }

  formData = {
    appointmentTypeId: "",
    selectedIntervalId: "",
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    middleName: "",
    age: "",
    note: "",
    recaptchaToken: "",
  };

  created() {
    if (!this.showAppointmentType) {
      this.formData.appointmentTypeId = this.appointmentTypeSelectOptions.second.value;
    }
  }

  get customValid() {
    return this.formData.appointmentTypeId !== "" && this.formData.selectedIntervalId !== "";
  }

  get intervalPlaceholder() {
    return this.formData.appointmentTypeId === "" ? "Сначала выберите тип приема" : "Выберите свободное время";
  }

  get firstVisitIntervals() {
    let tmpOptions = Object.values(this.timeOptionsBuffer);
    let newTimeOptions: SelectOptions = {};
    let justAdded = false;

    let prev = 0;

    for (let cur = 1; cur < tmpOptions.length; cur++) {
      const curOption = tmpOptions[cur];
      const prevOption = tmpOptions[prev];

      if (shouldInclude(prevOption, curOption) && !justAdded) {
        const timeBegin = new Date(prevOption.value.timeBegin);
        const timeEnd = new Date(curOption.value.timeEnd);
        newTimeOptions[cur.toString()] = {
          label: `${toHM(timeBegin)}`,
          value: { timeBegin, timeEnd },
        };

        justAdded = true;
      } else if (justAdded) {
        justAdded = false;
      }

      prev++;
    }

    return newTimeOptions;
  }

  get secondVisitIntervals() {
    return this.timeOptionsBuffer;
  }

  get currentIntervas() {
    if (this.formData.appointmentTypeId === "") return {};

    if (this.formData.appointmentTypeId === "first") return this.firstVisitIntervals;

    return this.secondVisitIntervals;
  }

  async onSubmit() {
    this.disabled = true;
    const {
      recaptchaToken,
      selectedIntervalId,
      note,
      email,
      lastName,
      firstName,
      middleName,
      phoneNumber,
      age,
    } = this.formData;
    const timeInterval: { timeBegin: Date; timeEnd: Date } = this.currentIntervas[selectedIntervalId].value;

    const variables: CreateOneApoitmentMutationVariables = {
      recaptchaToken,
      data: {
        timeBegin: new Date(timeInterval.timeBegin),
        timeEnd: new Date(timeInterval.timeEnd),
        note,
        schedule: {
          connect: {
            id: this.scheduleId,
          },
        },
        clientInfo: {
          create: {
            email,
            lastName,
            firstName,
            middleName,
            //убрать все пробелы
            phoneNumber: phoneNumber.replace(/\s/g, ""),
            age: Number(age),
          },
        },
      },
    };
    try {
      const result: {
        data?: CreateOneApoitmentMutation | null;
      } = await this.$apollo.mutate({
        mutation: CreateOneApoitment,
        variables,
      });

      if (!result.data) return;

      if (result?.data?.createOneAppointmentCustom?.id) {
        this.newAppointmentId = result.data.createOneAppointmentCustom.id;
      }

      this.showSuccessMessage = true;
      this.disabled = false;
    } catch (e) {
      this.errorMessage = e.message.replace("GraphQL error: ", "");
      this.showErrorMessage = true;
    }
  }

  async onClickErrorOk() {
    await this.$apollo.queries.schedule.refetch();
    this.showErrorMessage = false;
    this.disabled = false;
  }
}
