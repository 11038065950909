
import { Component, Vue } from "vue-property-decorator";
import SignIn from "../graphql/mutations/SignIn.graphql";
import { onLogin, getToken } from "../vue-apollo";
import jwt_decode from "jwt-decode";

@Component
export default class BaseAuth extends Vue {
  async authorize() {
    
    const token = getToken();
    let needUpdate = false;

    if (token) {
      const { exp: dateExpt, version: tokenVersionServer } = jwt_decode(token);
      const tokenVersionClient = Number(process.env.VUE_APP_TOKEN_VERSION || "0");

      if (!tokenVersionServer || Number(tokenVersionServer) < tokenVersionClient ) {
        needUpdate = true;
      } else {
        const dateNow = Math.round(Date.now() / 1000);

        if (dateExpt < dateNow) {
          needUpdate = true; //token expireid
        }
      }
    } else {
      needUpdate = true; //no token
    }

    if (needUpdate) {
      const result = await this.$apollo.mutate({
        mutation: SignIn,
        variables: { login: "guest", password: "guest" },
      });

      //Save token to localStorage
      await onLogin(this.$apollo, result.data.signin.token);
    }
  }
}
