





















































import { Vue, Component, Prop } from "vue-property-decorator";
import Educations from "./components/Educations.vue";
import Certificate from "./components/Certificate.vue";

@Component({
  components: { Educations, Certificate },
})
export default class EmployeeDescriptionSection extends Vue {
  @Prop({ type: Object, required: true })
  readonly employee!: any;

  @Prop({ type: Boolean, required: false, default: false })
  readonly pdf!: boolean;
  pdfClass = {
    "pdf-item": this.pdf,
  };

  get degree() {
    if (this.employee.degrees.length === 0) return "";

    if (!this.employee.degrees[0]?.name) return "";

    return ", " + this.employee.degrees[0].name;
  }

  gotTo(url: string) {
    window.location.href = url;
  }
}
