














































































import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import BaseWidget from "./BaseWidget.vue";

import CommentsTable from "../components/Comments/CommentsTable.vue";

import CreateCommentModalForm from "../components/Comments/CreateCommentModalForm.vue";

import VkAutocompleteInput from "../components/FormBuilder/Elements/VkAutocompleteInput.vue";
import VkSelect from "../components/FormBuilder/Elements/VkSelect.vue";
import VkLabel from "../components/FormBuilder/Elements/VkLabel.vue";

import FilterComments from "../graphql/queries/FilterComments.graphql";
import AutocompleteDepartments from "../graphql/queries/AutocompleteDepartments.graphql";

import BaseAuth from "../mixins/BaseAuth.vue";

import { FilterCommentsQuery, FilterCommentsQueryVariables } from "../types/types";
import { SelectOptions } from "@/components/FormBuilder/VkForm";

@Component({
  components: {
    BaseWidget,
    CreateCommentModalForm,
    CommentsTable,
    VkAutocompleteInput,
    VkSelect,
    VkLabel,
  },
  apollo: {
    filterResult: {
      fetchPolicy: "cache-first",
      query: FilterComments,
      update(data: FilterCommentsQuery) {
        return data;
      },

      variables(): FilterCommentsQueryVariables {
        return {
          take: this.take,
          skip: this.skip,
          publicationStatus: "1",
          ...(this.departmentId > 0 ? { departmentId: this.departmentId } : {}),
          ...(this.searchString.trim() !== "" ? { fullNameSearch: this.searchString.trim() } : {}),
        };
      },
    },
  },
})
export default class FilterCommentsWidget extends BaseAuth {
  page = 1;

  autocompleteDepartmentsQuery = AutocompleteDepartments;

  filterResult: FilterCommentsQuery = {
    filterComments: { comments: [], count: 0 },
  };

  searchString = "";
  departmentId = 0;

  showCreateComment: boolean = false;

  get take(): number {
    return Number(this.selectTake) ?? 10;
  }

  selectTake = "20";
  takeOptions: SelectOptions = {
    "20": { label: "20" },
    "50": { label: "50" },
    "100": { label: "100" },
  };

  closeCreateCommentDialog() {
    return (this.showCreateComment = false);
  }

  openCreateCommentDialog() {
    this.showCreateComment = true;
  }

  get skip() {
    return (this.page - 1) * this.take;
  }

  get commentsCount() {
    return this.filterResult.filterComments.count;
  }

  reset() {
    this.searchString = "";
    this.departmentId = 0;

    this.beforeSubmit();
    this.refresh();
  }

  refresh() {
    this.$apollo.queries.filterResult.refresh();
  }

  beforeSubmit() {
    this.page = 1;
  }

  async created() {
    this.$apollo.skipAll = true;
    await this.authorize();
    this.$apollo.skipAll = false;
  }
}
