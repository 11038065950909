






























































import { diffByDate, toHM } from "@/helpers/datesHelpers";
import {
  Appointment,
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables,
  ReadOneAppointmentQuery,
  ReadOneAppointmentQueryVariables,
} from "@/types/types";
import { Component } from "vue-property-decorator";
import AppointmentInfo from "../components/AppointmentInfo.vue";

import ReadOneAppointment from "../graphql/queries/ReadOneAppointment.graphql";
import CancelAppointment from "../graphql/mutations/CancelAppointment.graphql";
import BaseWidget from "./BaseWidget.vue";
import BaseAuth from "../mixins/BaseAuth.vue";

@Component({
  components: {
    AppointmentInfo,
    BaseWidget,
  },
  apollo: {
    appointment: {
      query: ReadOneAppointment,
      fetchPolicy: "cache-first",
      variables(): ReadOneAppointmentQueryVariables {
        return {
          id: Number(this.appointmentId),
          hash: String(this.appointmentHash),
        };
      },
      loadingKey: "loadingQueriesCount",
      update(data: ReadOneAppointmentQuery) {
        if (!data?.appointmentPublic) {
          document.title = "Идет загрузка...";
          return null;
        }

        document.title = `Информация о записи №${data.appointmentPublic.id}` ?? "Идет загрузка...";
        return data.appointmentPublic;
      },
      errorPolicy: "ignore",
    },
  },
})
export default class AppointmentWidget extends BaseAuth {
  appointment: Appointment | null = null;
  showConfirmCancel = false;
  isloadingCancel = false;
  loadingQueriesCount = 0;

  get isAppointmentExpired() {
    if (this.appointment === null) return false;

    const appointmentDate = new Date(this.appointment.schedule.scheduleDate);
    const today = new Date();

    return diffByDate(appointmentDate, today) + 1 < 0;
  }

  get isLoadingAppointment() {
    return this.loadingQueriesCount !== 0;
  }

  get appointmentId(): number {
    const url = new URL(window.location.href);
    return Number(url.searchParams.get("appointmentId"));
  }

  get appointmentHash(): string {
    const url = new URL(window.location.href);
    return String(url.searchParams.get("appointmentHash"));
  }

  get employeeLink(): string {
    if (this.appointment === null) return "";

    return `/employee?employeeId=${this.appointment.schedule.employee.id}`;
  }

  get formatedTimeInfo(): string {
    if (this.appointment === null) return "";

    const date = new Date(this.appointment.schedule.scheduleDate)
      //@ts-ignore
      .toLocaleString("ru-RU", { dateStyle: "short" });
    const time = toHM(new Date(this.appointment.timeBegin));
    return `${date} в ${time}`;
  }

  get cabinetLabel(): string {
    if (this.appointment === null) return "";

    return this.appointment.schedule.cabinet?.number ?? "Не указан";
  }

  async created() {
    this.$apollo.skipAll = true;
    await this.authorize();
    this.$apollo.skipAll = false;
  }

  async cancelAppointment() {
    this.showConfirmCancel = false;

    if (this.appointment.isCanceled) return;

    this.isloadingCancel = true;

    const variables: CancelAppointmentMutationVariables = {
      id: this.appointmentId,
      hash: this.appointmentHash,
    };

    const result: {
      data?: CancelAppointmentMutation;
    } = await this.$apollo.mutate({
      mutation: CancelAppointment,
      variables,
    });

    if (result.data.cancelAppointmentPublic.isCanceled) {
      this.appointment.isCanceled = true;
    }

    this.isloadingCancel = false;
  }
}
