import Vue, { VueConstructor } from "vue";
import FilterEmployeesWidget from "./widgets/FilterEmployeesWidget.vue";
import FilterCommentsWidget from "./widgets/FilterCommentsWidget.vue";

import EmployeeWidget from "./widgets/EmployeeWidget.vue";
import DepartmentEmployeesWidget from "./widgets/DepartmentEmployeesWidget.vue";
import DepartmentCommentsWidget from "./widgets/DepartmentCommentsWidget.vue";
import WeekScheduleWidget from "./widgets/WeekScheduleWidget.vue";
import AppointmentWidget from "./widgets/AppointmentWidget.vue";

import { createProvider } from "./vue-apollo";
import swearWords from "./swear-config";

import { localize, configure, extend, setInteractionMode } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";

import * as rules from "vee-validate/dist/rules";

setInteractionMode("lazy");

for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  });
}

extend("no-swear", {
  validate: (value: string) => {
    const noPunctuationValue = value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, " ");

    return !noPunctuationValue.split(" ").some(word => swearWords.includes(word));
  },
  message: () => {
    return "Нецензурная речь, бранные слова, обороты и выражения запрещены";
  },
});

extend("is-true", {
  validate: (value: boolean) => {
    return value;
  },
  message: () => {
    return "Это поле обязательно для заполнения";
  },
});

localize("ru", ru);

configure({
  classes: {
    invalid: "uk-form-danger",
  },
});

import Cleave from "cleave.js";
require("cleave.js/dist/addons/cleave-phone.ru");

Vue.directive("cleave", {
  inserted: (el: any, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
  update: (el: any) => {
    const event = new Event("input", { bubbles: true });
    setTimeout(function() {
      el.value = el.cleave.properties.result;
      el.dispatchEvent(event);
    }, 100);
  },
});

enum Widget {
  Employee = "wg-employee",
  EmployeeWeekSchedule = "wg-employee-week-schedule",

  FilterEmployees = "wg-filter-employees",
  FilterComments = "wg-filter-comments",

  DepartmentEmployees = "wg-department-employees",
  DepartmentComments = "wg-department-comments",
  Appointment = "wg-appoitment",
}

function mountVue(elementId: string, Component: VueConstructor<Vue>, props?: object) {
  new Vue({
    apolloProvider: createProvider(),
    render: h =>
      h(Component, {
        props,
      }),
  }).$mount(`#${elementId}`);
}

function mountElement(elementId: string, element: HTMLElement) {
  Vue.config.productionTip = false;

  switch (elementId) {
    case Widget.FilterEmployees:
      return mountVue(elementId, FilterEmployeesWidget);
    case Widget.Employee:
      return mountVue(elementId, EmployeeWidget);
    case Widget.DepartmentEmployees:
      return mountVue(elementId, DepartmentEmployeesWidget, {
        departmentId: Number(element.getAttribute("department-id")),
      });
    case Widget.DepartmentComments:
      return mountVue(elementId, DepartmentCommentsWidget, {
        departmentId: Number(element.getAttribute("department-id")),
      });
    case Widget.EmployeeWeekSchedule:
      return mountVue(elementId, WeekScheduleWidget, {
        employeeId: Number(element.getAttribute("employee-id")),
        successMessage: element.getAttribute("success-message"),
        showAppointmentType: element.getAttribute("show-appointment-type") === "true",
        hoverTooltip: element.getAttribute("hover-tooltip"),
      });
    case Widget.FilterComments:
      return mountVue(elementId, FilterCommentsWidget);
    case Widget.Appointment:
      return mountVue(elementId, AppointmentWidget);
    default:
      console.warn(`Expected 'id' to be in [${Object.values(Widget).join(", ")}], got ${elementId} instead.`);
      break;
  }
}

let observer = new MutationObserver(function(mutations, me) {
  const elementsIds = Object.values(Widget);

  for (const elementId of elementsIds) {
    let element = document.getElementById(elementId);
    if (element) {
      mountElement(elementId, element);
    }
  }

  me.disconnect(); // stop observing
  return;
});

// start observing
observer.observe(document, {
  childList: true,
  subtree: true,
});
