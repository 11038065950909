

































































import { Component, Vue, Provide } from "vue-property-decorator";
import VkForm from "../components/FormBuilder/VkForm.vue";
import FilterEmployees from "../graphql/queries/FilterEmployees.graphql";
import { FilterEmployeesQuery } from "../types/types";
import BaseWidget from "./BaseWidget.vue";
import BaseAuth from "../mixins/BaseAuth.vue";
import { retrieveFromData } from "@/services/formBackupService";

@Component({
  components: { VkForm, BaseWidget },
})
export default class FilterEmployeesWidget extends BaseAuth {
  page = 1;
  take = 10;

  filterQuery = FilterEmployees;
  queryResult: FilterEmployeesQuery = { filterEmployees: { employees: [], count: 0 } };

  fetchPolicy = "cache-first";
  preserveKey = "filterEmployees";

  get skip() {
    return (this.page - 1) * this.take;
  }

  set skip(newSkip: number) {

    this.page = newSkip / this.take + 1;
  }

  get employeeCount() {
    return this.queryResult.filterEmployees.count;
  }

  refreshFunction: Function = async () => { };

  @Provide()
  setRefresh(refreshFunction: Function) {
    this.refreshFunction = refreshFunction;
  }

  beforeSubmit() {
    this.page = 1;
    return true;
  }

  updateTable(result: FilterEmployeesQuery) {
    this.queryResult = result;
  }

  async mounted() {
    const savedFormProps = retrieveFromData(this.preserveKey);

    if (savedFormProps) {
      this.take = savedFormProps.take;
    }

    this.fetchPolicy = "network-only";
    await this.refreshFunction();
    this.fetchPolicy = "cache-first";

    if (savedFormProps) {
      this.skip = savedFormProps.skip;
    }
  }
}
