









import Vue from 'vue'

export default Vue.extend({
    props: {
        required: {
            type: Boolean,
            default: true
        }
    },
    name: 'vk-label'
})
