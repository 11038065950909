































































import { Vue, Component, Prop } from "vue-property-decorator";
import VkForm from "../FormBuilder/VkForm.vue";

import CreateOneComment from "../../graphql/mutations/CreateOneComment.graphql";
import InitEmployeeComment from "../../graphql/queries/InitEmployeeComment.graphql";

@Component({
  components: {
    VkForm,
  },
})
export default class CreateCommentModalForm extends Vue {
  @Prop({ type: Number, required: false, default: -1 })
  readonly employeeId!: number;

  @Prop({ type: Number, required: false, default: -1 })
  readonly departmentId!: number;

  @Prop({ type: Boolean, required: true })
  readonly showCreateComment!: boolean;

  @Prop({ type: Function, required: true })
  readonly onClose!: Function;

  showSuccessMessage = false;
  showErrorMessage = false;
  loading = false;

  commentQuery = CreateOneComment; //CreateComment
  initRequest = InitEmployeeComment; //вот тут инициализация

  get needInit() {
    return this.employeeId > 0;
  }
  //костыльно, но рабоче
  customInitResult(result: any) {
    //если задан емплои айди или это пустая форма - не трогаем
    if (this.employeeId > 0 || this.departmentId < 0) return result;

    return {
      data: {
        employee: {
          id: -1,
          departments: [
            {
              id: this.departmentId,
            },
          ],
        },
      },
    };
  }

  afterSubmit(result: any) {
    if (result.data.createOneCommentCustom !== null) {
      this.showSuccessMessage = true;
    } else {
      this.showErrorMessage = true;
    }
    this.loading = false;
  }

  get initArgs() {
    return {
      ...(this.employeeId > 0 ? { employeeId: this.employeeId } : {}),
      ...(this.departmentId > 0 ? { departmentId: this.employeeId } : {}),
    };
  }
}
