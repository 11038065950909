import { AutocompleteDepartmentsQuery, AutocompleteEmployeesQuery, AutocompletePositionsQuery } from "../types/types";
import AutocompleteDepartments from "../graphql/queries/AutocompleteDepartments.graphql";
import AutocompletePositions from "../graphql/queries/AutocompletePositions.graphql";
import AutocompleteEmployees from "../graphql/queries/AutocompleteEmployees.graphql";

import { DocumentNode } from "graphql";

module GQLAutocompleteConverter {
  export function convertOutput(result: any, request: DocumentNode): [number, string][] {
    return requestMap.find(r => r.request === request)!.convertOutput(result)!;
  }

  interface ConverTagtMap {
    request: DocumentNode;
    convertOutput: (result: any | null | undefined) => [number, string][];
  }

  const requestMap: ConverTagtMap[] = [
    {
      request: AutocompleteDepartments,
      convertOutput: (result: AutocompleteDepartmentsQuery | null | undefined): [number, string][] => {
        const departments = result?.departments;
        return departments?.map(d => [d.id, d.name!]) ?? [];
      },
    },
    {
      request: AutocompletePositions,
      convertOutput: (result: AutocompletePositionsQuery | null | undefined): [number, string][] => {
        const positions = result?.positions;
        return positions?.map(p => [p.id, p.name!]) ?? [];
      },
    },
    {
      request: AutocompleteEmployees,
      convertOutput: (result: AutocompleteEmployeesQuery | null | undefined): [number, string][] => {
        const employees = result?.employees;

        return employees?.map(e => [e.id, e.fullName!]) ?? [];
      },
    },
  ];
}

export default GQLAutocompleteConverter;
