
























import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import CommentsTableBase from "./CommentsTableBase.vue";
import CommentsTableRowHeader from "./CommentsTableRowHeader.vue";
import CommentsTableRowBody from "./CommentsTableRowBody.vue";

@Component({
  components: {
    CommentsTableBase,
    CommentsTableRowHeader,
    CommentsTableRowBody,
  },
})
export default class CommentsTable extends Vue {
  @PropSync("page", { type: Number, required: true })
  syncedPage!: number;

  @Prop({ type: Number, required: true })
  readonly take!: number;

  @Prop({ type: Number, required: true })
  readonly commentsCount!: number;

  @Prop({ type: Array, required: true })
  readonly comments!: any[];

  @Prop({ type: Boolean, required: false, default: false })
  readonly shortened!: boolean;
}
