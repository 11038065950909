
import { Component, Prop, Provide, Inject, Watch, PropSync } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import DefaultFormBuilder from "../FormBuilder/DefaultFormBuilder.vue";
import { IFieldSet } from "../FormBuilder/VkForm";

import { DocumentNode } from "graphql";
import GQLFormDataConverter from "../../api/GQLFormDataConverter";
import GQLMutationConverter from "../../api/GQLMutationConverter";
import GQLFilterConverter from "../../api/GQLFilterConverter";
import BaseAuth from "../../mixins/BaseAuth.vue";

import { saveFormData, retrieveFromData, eraseFormData } from "../../services/formBackupService";

type ActionType = "filter" | "mutation";

@Component({
  components: {
    ValidationObserver,
    DefaultFormBuilder,
  },
})
export default class VkForm extends BaseAuth {
  @Prop({ type: Boolean, required: false, default: false })
  readonly cleanMutation!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly autoupdate!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly prefetch!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly init!: boolean;

  @Prop({ type: String, required: true })
  readonly actionType!: ActionType;

  @Prop({ type: Object, required: false, default: null })
  readonly initRequest!: DocumentNode;

  @Prop({ type: Object, required: true })
  readonly submitRequest!: DocumentNode;

  @Prop({ type: Number, required: false, default: null })
  readonly recordId!: number | null;

  @Prop({ type: Function, default: (f: IFieldSet[]) => f })
  readonly customInit!: (formData: IFieldSet[]) => IFieldSet[];

  @Prop({ type: Function, default: (result: any) => result })
  readonly customInitResult!: (result: any) => any;

  @Prop({ type: Function, default: () => {} })
  readonly onCancel!: () => void;

  @Prop({ type: Function, default: () => {} })
  readonly afterSubmit!: (data: any) => void;

  @Prop({ type: Function, default: () => true })
  readonly beforeSubmit!: () => boolean;

  @Prop({ type: String, default: "cache-first", required: false })
  readonly fetchPolicy!: "network-only" | "cache-first" | "cache-only" | "no-cache" | "standby" | undefined;

  @PropSync("skip", { type: Number, required: false })
  syncedSkip!: number;

  @PropSync("take", { type: Number, required: false })
  syncedTake!: number;

  @Prop({ type: String, required: false, default: "" })
  readonly preserveKey!: string;

  @Prop({ type: Object, required: false, default: null })
  readonly initArgs!: Object | null;

  @Prop({ required: false, type: String, default: "1-1" })
  readonly childWidth!: String;

  @Provide()
  customValidate(isValid: boolean) {
    this.customValid = isValid;
  }

  @Prop({ type: Function, default: () => {} })
  readonly afterInit!: () => void;

  @Inject({ default: (refreshFunction: Function) => {} })
  setRefresh!: (refreshFunction: Function) => void;

  @Inject({ default: (refreshFunction: Function) => {} })
  setForceInit!: (initFunction: Function) => void;

  fieldsChanged: boolean = false;
  takeChanged: boolean = false;
  customValid: boolean = true;

  //возможно просто в onMount
  formData: IFieldSet[] = [];

  async refreshFunction() {
    
    if (this.formData.length === 0) {
      await this.initForm();
    }
    return this.onSubmit()
  }

  mounted() {
    if (this.setRefresh) this.setRefresh(this.refreshFunction);
    if (this.setForceInit) this.setForceInit(this.forceInit);
  }

  forceInit() {
    this.formData = this.customInit(this.formData);
    this.$forceUpdate();
  }

  async initForm() {
    switch (this.actionType) {
      case "mutation":
        let result = null;
        if (this.init) {
          let initVariables: any = {};
          if (this.recordId) {
            initVariables.id = this.recordId;
          }
          if (this.initArgs) {
            initVariables = {
              ...initVariables,
              ...this.initArgs,
            };
          }
          result = await this.$apollo.query({
            query: this.initRequest,
            variables: initVariables,
            fetchPolicy: "network-only",
          });
        }
        result = this.customInitResult(result);
        
        //тут потеницальные проблемы
        this.formData = GQLFormDataConverter.convertOutput(result?.data, this.initRequest ?? this.submitRequest);
        
        this.formData = this.customInit(this.formData);
        this.afterInit();
        break;
      case "filter":
        let tmpData: IFieldSet[] = GQLFilterConverter.initInput(this.submitRequest);
        let savedData: IFieldSet[] = [];

        if (this.preserveKey) {
          const savedProps = retrieveFromData(this.preserveKey);

          if (savedProps) {
            savedData = savedProps.formData;
          }
        }

        if (savedData.length !== 0 && savedData.length === tmpData.length) {
          for (let fieldSetIndex = 0; fieldSetIndex < tmpData.length; fieldSetIndex++) {
            const fieldSet = tmpData[fieldSetIndex];

            for (let fieldIndex = 0; fieldIndex < fieldSet.fields.length; fieldIndex++) {
              fieldSet.fields[fieldIndex].value = savedData[fieldSetIndex].fields[fieldIndex].value;
              fieldSet.fields[fieldIndex].hiddenId = savedData[fieldSetIndex].fields[fieldIndex].hiddenId;
            }
          }
        }

        this.formData = [...tmpData];

        if (this.prefetch) {
          await this.onSubmit();
        }
        break;
      default:
        break;
    }
    
  }

  async created() {
    await this.authorize();
    await this.initForm();
    
  }

  formComponentKey = 0;

  forceUpdateForm() {
    this.formComponentKey += 1;
  }

  async resetForm() {
    if (this.preserveKey !== "") {
      eraseFormData(this.preserveKey);
    }
    await this.initForm();
    this.forceUpdateForm();
  }

  preseveFormProps() {
    const payload = {
      formKey: this.preserveKey,
      formProps: {
        formData: this.formData,
        skip: this.syncedSkip,
        take: this.syncedTake,
      },
    };

    saveFormData(payload);
  }

  @Watch("formData", { deep: true })
  async onFormDataUpdate(o: any, n: any) {
    if (this.preserveKey !== "") {
      this.preseveFormProps();
    }

    if (this.autoupdate) {
      
      this.fieldsChanged = true;
      await this.onSubmit();
    }
  }

  @Watch("syncedSkip")
  async onSkipChange(o: any, n: any) {
 

    if (this.preserveKey !== "") {
      this.preseveFormProps();
    }
    if (this.autoupdate && !this.takeChanged) {
      await this.onSubmit();
    }
  }

  @Watch("syncedTake")
  async onTakeChange(o: any, n: any) {
    if (this.autoupdate && !this.fieldsChanged) {
      this.takeChanged = true;

      await this.onSubmit();
    }
  }

  @Watch("recordId")
  async onRecorIdChange(o: number, n: number) {
    if (n > 0 && n !== o) {
      await this.initForm();
    }
  }

  async onSubmit() {
    if (this.formData.length === 0) {
      this.fieldsChanged = false;
      this.takeChanged = false;
      return;
    }

    if (this.fieldsChanged || this.takeChanged) {
      //ставим страницу в 1 И не даем ей еще раз обновиться
      this.beforeSubmit();
    }

    
    if (this.actionType === "mutation") {
      const variables = GQLMutationConverter.convertInput(this.formData, this.submitRequest, this.recordId);

      const result = await this.$apollo.mutate({
        mutation: this.submitRequest,
        variables,
      });

      this.afterSubmit(result);
    }

    if (this.actionType === "filter") {
      let variables: any = GQLFilterConverter.convertInput(this.formData, this.submitRequest);

      
      if (this.syncedTake !== undefined && this.syncedSkip !== undefined) {
        let skip = 0,
          take = this.syncedTake;
        //если меняются поля - игнорим скип, он и так ставится в ноль
        //в противном случае юзаем страницу нужную
        if (!this.fieldsChanged) {
          skip = this.syncedSkip;
        }
        variables = {
          ...variables,
          skip,
          take, // поменял
        };
      }

      const { data } = await this.$apollo.query({
        query: this.submitRequest,
        variables,
        fetchPolicy: "network-only",
      });

      this.afterSubmit(data);

      this.fieldsChanged = false;
      
      this.takeChanged = false;
    }
  }
}
