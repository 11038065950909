








import { Vue, Component, Prop } from "vue-property-decorator";
import { getValidDateString, isValidString } from "./utils";

@Component
export default class Educations extends Vue {
  @Prop({ type: String, required: false, default: "-" })
  readonly institution!: string;

  @Prop({ type: String, required: false, default: "-" })
  readonly diplomaSpecialty!: string;

  @Prop({ type: String, required: false, default: "1900" })
  readonly graduationYear!: string;

  get composedEducation() {
    return [
      this.institution,
      this.diplomaSpecialty,
      getValidDateString(this.graduationYear, (date) => date.getFullYear().toString()),
    ]
      .filter(isValidString)
      .join(". ");
  }

  get isValidEducation() {
    return this.composedEducation !== "";
  }
}
