






























import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

@Component
export default class CommentsTableBase extends Vue {
  @PropSync("page", { type: Number, required: true })
  syncedPage!: number;

  @Prop({ type: Number, required: true })
  readonly take!: number;

  @Prop({ type: Number, required: true })
  readonly commentsCount!: number;

  get maxPages() {
    return Math.ceil(this.commentsCount / this.take);
  }
}
