import { CreateOneCommentMutationVariables } from "./../types/types";

import { DocumentNode } from "graphql";
import { IFieldSet } from "../components/FormBuilder/VkForm";

import CreateOneComment from "../graphql/mutations/CreateOneComment.graphql";

module GQLDataConverter {
  export function convertInput(fields: IFieldSet[], request: DocumentNode, id: number | null) {
    return requestMap.find(r => r.request === request)!.convertInput(fields, id);
  }

  const requestMap = [
    {
      request: CreateOneComment,
      convertInput: (fieldSets: IFieldSet[], id: number | null): CreateOneCommentMutationVariables =>
        convertCommentInput(fieldSets),
    },
  ];

  function convertCommentInput(fieldSets: IFieldSet[]): CreateOneCommentMutationVariables {
    const departmentId = fieldSets[0].fields[0].value as number;
    const employeeId = fieldSets[0].fields[1].value as number;
    const recaptchaToken = fieldSets[3].fields[0].value as string;

    return {
      recaptchaToken,
      data: {
        ...(departmentId > 0
          ? {
              department: {
                connect: {
                  id: departmentId,
                },
              },
            }
          : {}),

        ...(employeeId > 0
          ? {
              employee: {
                connect: {
                  id: employeeId,
                },
              },
            }
          : {}),

        authorName: (fieldSets[1].fields[0].value as string).trim(),
        authorEmail: (fieldSets[1].fields[1].value as string).trim(),

        commentText: fieldSets[2].fields[0].value as string,
        commentDate: new Date(),
        published: false,
      },
    };
  }
}

export default GQLDataConverter;
